import { createRouter, createWebHistory } from "vue-router"
import store from "../store/index"
import authenticateGuard from "./navigation-guards/authenticate.guard"

import EligibilityReminder from "@/views/EligibilityReminder"
import Ineligible from "@/views/Ineligible"
import FormWizard from "@/views/FormWizard"
import ConfirmDetails from "@/views/ConfirmDetails"
import UpdateDetails from "@/views/UpdateDetails"
import CriteriaOne from "@/views/CriteriaOne"
import CriteriaTwo from "@/views/CriteriaTwo"
import EligibilityProof from "@/views/EligibilityProof"
import ApplicationSummary from "@/views/ApplicationSummary"
import Completed from "@/views/Completed"
import NotFound from "@/views/NotFound"
import CheckStatus from "@/views/CheckStatus"
import HoldingScreen from "@/views/HoldingScreen"
import ErrorScreen from "@/views/ErrorScreen"
import EnglandWalesScreen from "@/views/EnglandWalesScreen"

import UserService from "@/services/UserService.js"
import AuthenticationService from "@soenergy/frontend-library/src/services/AuthenticationService"

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "ConfirmDetails",
      component: ConfirmDetails,
      meta: {
        authenticate: true,
      },
    },
    {
      path: "/applications-closed",
      name: "HoldingScreen",
      component: HoldingScreen,
    },
    {
      path: "/eligibility",
      name: "EligibilityReminder",
      component: EligibilityReminder,
      meta: {
        authenticate: true,
        promptBeforeLeaving: true,
      },
    },
    {
      path: "/ineligible",
      name: "Ineligible",
      component: Ineligible,
    },
    {
      path: "/completed",
      name: "Completed",
      component: Completed,
    },
    {
      path: "/",
      component: FormWizard,
      children: [
        {
          path: "details",
          name: "UpdateDetails",
          component: UpdateDetails,
        },
        {
          path: "criteria1",
          name: "Criteria1",
          component: CriteriaOne,
        },
        {
          path: "criteria2",
          name: "Criteria2",
          component: CriteriaTwo,
        },
        {
          path: "eligibility-proof",
          name: "EligibilityProof",
          component: EligibilityProof,
        },
        {
          path: "summary",
          name: "Summary",
          component: ApplicationSummary,
        },
      ],
      meta: {
        authenticate: true,
        promptBeforeLeaving: true,
      },
    },
    {
      path: "/status",
      name: "CheckStatus",
      component: CheckStatus,
      meta: {
        authenticate: true,
      },
    },
    {
      path: "/unexpected-error",
      name: "ErrorScreen",
      component: ErrorScreen,
    },
    {
      path: "/england-and-wales-customers",
      name: "EnglandWalesScreen",
      component: EnglandWalesScreen,
    },
    {
      path: "/:pathMatch(.*)*",
      name: "NotFound",
      component: NotFound,
    },
  ],
  scrollBehavior: () => ({ top: 0 }),
})

router.beforeEach(async (to, from, next) => {
  let isAuthenticated = false
  const getAccountIdRoute =
    to.path === "/" || from.name === null || from.name === undefined

  if (getAccountIdRoute) {
    store.commit("UPDATE_LOADING", true)
    isAuthenticated =
      await AuthenticationService.checkIfAuthenticatedAndFetchTokenIfNeeded()

    if (isAuthenticated) {
      await UserService.getAccountId()
        .then((response) => {
          if (response && response.data && response.data.current_account_id) {
            const accountId = response.data.current_account_id
            const email = response.data.email
            store.commit("SET_CUSTOMER_CREDENTIALS", accountId)
            store.commit("SET_EMAIL", email)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
    store.commit("UPDATE_LOADING", false)
  }

  if (from.name === "EnglandWalesScreen") {
    return false
  }

  next()
})

router.beforeEach(authenticateGuard)

export default router
