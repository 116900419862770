<template>
  <section v-if="pageContent" class="eligibility-proof step-wrapper">
    <div class="step-content">
      <Article :text-content="pageContent.description" />

      <FileUploader
        :uploaded-files="uploadedFiles"
        :server-error="serverError"
        :file-extensions="fileUploader.fileExtensions"
        :upload-file-quantity="fileUploader.uploadFileQuantity"
        :upload-file-size-bytes="fileUploader.uploadFileSize"
        :upload-title="fileUploader.uploadTitle"
        :upload-btn-label="fileUploader.uploadBtnLabel"
        :invalid-size-error="fileUploader.invalidSizeError"
        :missing-file-message="fileUploader.missingFileMessage"
        @upload="uploadFile"
        @remove="removeFile"
      />
    </div>

    <div class="step-nav-buttons">
      <AppButton
        class="step-nav-buttons__back"
        variant="transparentSecondary"
        @click="$emit('go-back')"
      >
        <span class="icon icon-ctrl-left"></span>
        <span>{{ pageContent.backBtnText }}</span>
      </AppButton>
      <AppButton
        class="step-nav-buttons__next"
        variant="primary"
        @click="$emit('go-forward')"
      >
        <span>{{ pageContent.nextBtnText }}</span>
        <span class="icon icon-ctrl-right"></span>
      </AppButton>
    </div>
  </section>
</template>

<script>
import { mapMutations, mapState } from "vuex"
import AppButton from "@soenergy/frontend-library/src/components/AppButton"
import Article from "@soenergy/frontend-library/src/components/Article"
import FileUploader from "@soenergy/frontend-library/src/components/FileUploader"
import ErrorReporter from "@soenergy/frontend-library/src/services/ErrorReporter"

import RailsService from "@/services/RailsService"
import cmsPreviewMixin from "@soenergy/frontend-library/src/mixins/cmsPreviewMixin"
import eligibilityProofContent from "soenergy-cms-loader!?path=whd/eligibility-proof"

export default {
  components: {
    AppButton,
    Article,
    FileUploader,
  },
  mixins: [cmsPreviewMixin({ story: eligibilityProofContent })],
  emits: ["go-back", "go-forward"],
  data: () => ({
    serverError: "",
  }),
  computed: {
    ...mapState({
      accountId: (state) => state.accountId,
    }),
    uploadedFiles: {
      get() {
        return this.$store.state.whdApplication.form.uploadedFiles
      },
      set(value) {
        this.setUploadedFiles(value)
      },
    },
    fileUploader() {
      return this.pageContent.fileUploader &&
        this.pageContent.fileUploader.length
        ? this.pageContent.fileUploader[0]
        : {}
    },
  },
  methods: {
    ...mapMutations("whdApplication", {
      setUploadedFiles: "SET_UPLOADED_FILES",
    }),
    async uploadFile(file) {
      if (file) {
        let fileName = file.lastModified + "_" + file.name
        let uploadUrl = await this.requestUploadUrl(fileName)
        RailsService.uploadImage(uploadUrl, file)
          .then(() => {
            // save the file locally in vuex
            this.uploadedFiles.push(file)
          })
          .catch((error) => {
            this.serverError = error
            ErrorReporter.report(error)
          })
      }
    },
    async requestUploadUrl(fileName) {
      let url = ""
      await RailsService.requestImageUpload(this.accountId, fileName)
        .then((response) => {
          if (response && response.data && response.data.signedUrl) {
            url = response.data.signedUrl
          }
        })
        .catch((error) => {
          this.serverError = error
        })
      return url
    },
    async removeFile(data) {
      let fileName = data.file.lastModified + "_" + data.file.name
      await RailsService.deleteImage(this.accountId, fileName)
        .then(() => {
          this.uploadedFiles.splice(data.index, 1)
        })
        .catch((error) => {
          ErrorReporter.report(error)
        })
    },
  },
}
</script>
