<template>
  <div
    id="app"
    :class="[
      { app__completed: completedLayout },
      { app__journey: !completedLayout },
    ]"
  >
    <AppHeader :hide-menu="true" :deployment-url="vercelUrl" />
    <router-view class="content" />
    <LoginModal ref="login-modal" :show="showAuthError" />
    <LoadingOverlay :is-active="loading" transition="fade-out" />
    <template
      v-if="
        cookieBannerContent &&
        cookieModalContent &&
        !isThirdPartyCookieConsentEnabled
      "
    >
      <CookieManager
        :banner-text="cookieBannerContent.bannerText[0].textContent"
        :accept-all-button="cookieBannerContent.buttons[0]"
        :manage-cookies-button="cookieBannerContent.buttons[1]"
        :has-manage-cookies="true"
        :modal-title="cookieModalContent.modalTitle"
        :modal-sub-title="cookieModalContent.modalSubTitle"
        :accept-button="cookieModalContent.acceptButton"
        :save-button="cookieModalContent.saveButton"
        :modal-description="cookieModalContent.modalDescription[0].textContent"
        :necessary-description="
          cookieModalContent.necessaryDescription[0].textContent
        "
        :performance-description="
          cookieModalContent.performanceDescription[0].textContent
        "
        :functional-description="
          cookieModalContent.functionalDescription[0].textContent
        "
        :advertising-description="
          cookieModalContent.advertisingDescription[0].textContent
        "
      >
      </CookieManager>
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex"
import cmsPreviewMixin from "@soenergy/frontend-library/src/mixins/cmsPreviewMixin"
import AppHeader from "@soenergy/frontend-library/src/components/AppHeader"
import cookieModalContent from "soenergy-cms-loader!?path=common/cookie-modal"
import cookieBannerContent from "soenergy-cms-loader!?path=common/cookie-banner"
import LoadingOverlay from "@soenergy/frontend-library/src/components/LoadingOverlay"
import CookieManager from "@soenergy/frontend-library/src/components/CookieBanner/CookieManager"
import { PROJECTS } from "@soenergy/frontend-library/src/config/projectRouting"

import LoginModal from "@/components/LoginModal"

export default {
  name: "App",
  components: {
    AppHeader,
    CookieManager,
    LoginModal,
    LoadingOverlay,
  },
  mixins: [
    cmsPreviewMixin({ story: cookieModalContent, name: "cookieModalContent" }),
    cmsPreviewMixin({
      story: cookieBannerContent,
      name: "cookieBannerContent",
    }),
  ],
  provide() {
    return {
      currentProject: PROJECTS.Whd,
    }
  },
  data() {
    return {
      isThirdPartyCookieConsentEnabled:
        process.env.VUE_APP_USE_THIRD_PARTY_COOKIE_CONSENT === "true",
      vercelUrl: process.env.VERCEL_URL,
    }
  },
  computed: {
    ...mapState({
      showAuthError: (state) => state.showAuthError,
      loading: (state) => state.loading,
    }),
    completedLayout() {
      return this.$route.path === "/completed" || this.$route.path === "/status"
    },
  },
  created() {
    this.registerLeaveConfirmPrompt()
  },
  methods: {
    registerLeaveConfirmPrompt() {
      window.addEventListener("beforeunload", (event) => {
        const isLeavingToWebsite = () => {
          if (!document.activeElement) {
            return false
          }
          const selectedLink = document.activeElement.href
          if (selectedLink) {
            return selectedLink.includes(process.env.VUE_APP_RAILS_API_URL)
          }
        }
        const inducePrompt = () => {
          event.preventDefault()
          event.returnValue = ""
        }
        const promptBeforeLeaving = this.$route.matched.some(
          (route) => route.meta.promptBeforeLeaving
        )
        if (promptBeforeLeaving && !isLeavingToWebsite()) {
          inducePrompt()
        }
      })
    },
  },
}
</script>

<style lang="scss">
$headerHeightMobile: 64px;
$headerHeightDesktop: 80px;

#app {
  min-height: 100vh;
}
.content {
  display: flex;
  flex-flow: column;
  background-color: $day;
  width: 100%;
  margin: 0 auto;
  padding: $spacing-4 $spacing-4 0;
  overflow-y: auto;
  min-height: calc(100vh - $headerHeightMobile);

  @include sm {
    padding: $spacing-4 $spacing-7 0;
  }

  @include lg {
    max-width: 830px;
    min-height: calc(100vh - $headerHeightDesktop);
  }
}

.app {
  &__journey {
    background-color: $water;
  }

  &__completed {
    background-color: $day-dark;
  }
}

.step-wrapper {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.step-content {
  flex: 1 1 auto;
  margin-bottom: $space-8;
}

.step-nav-buttons {
  padding: $space-4 $space-10;
  background-color: $day-dark;
  margin: $space-0 (-$space-4) $space-0;

  @include sm {
    margin: $space-0 (-$space-10) $space-0;
  }
}
.step-nav-buttons__back {
  margin-right: $space-3;
}
</style>
