<template>
  <div class="file-preview"></div>
</template>

<script>
export default {
  props: {
    file: {
      type: File,
      default: null
    },
    errorMessage: {
      type: String,
      default: "This file can not be displayed."
    }
  },
  watch: {
    file: {
      handler(file) {
        if (!file) {
          this.clearContainer()
          return
        }

        if (file.type === "application/pdf") {
          this.renderEmbed()
        } else if (
          file.type === "image/png" ||
          file.type === "image/jpg" ||
          file.type === "image/jpeg"
        ) {
          this.renderImage()
        } else {
          this.renderError()
        }
      },
      deep: true
    }
  },
  methods: {
    clearContainer() {
      this.$el.innerHTML = ""
    },
    renderEmbed() {
      this.clearContainer()

      let embed = document.createElement("embed")
      let src = URL.createObjectURL(this.file)
      let srcWithProps = src + "#toolbar=0&navpanes=0"
      embed.setAttribute("src", srcWithProps)
      embed.setAttribute("class", "file-preview__embed")

      this.$el.appendChild(embed)
    },
    renderImage() {
      this.clearContainer()

      let image = document.createElement("img")
      let src = URL.createObjectURL(this.file)
      image.setAttribute("src", src)
      image.setAttribute("class", "file-preview__image")

      this.$el.appendChild(image)
    },
    renderError() {
      this.clearContainer()

      let message = document.createElement("p")
      message.setAttribute("class", "file-preview__error")
      message.innerHTML = this.errorMessage
      this.$el.appendChild(message)
    }
  }
}
</script>

<style lang="scss">
.file-preview {
  &__embed {
    width: 100%;
    min-height: 400px;
    min-width: 400px;
  }
  &__image {
    width: 100%;
  }
  &__error {
    color: $fire-dark;
    font-size: $font-size-5;
    margin: $space-5 0;
  }
}
</style>
