<template>
  <transition :name="fullScreenAnimation" appear>
    <div v-if="isModalOpen" class="modal-backdrop" @click.self="closeModal">
      <div
        ref="modal"
        class="modal"
        :class="[
          { 'modal__close-icon-hide': !showCloseIcon },
          { 'modal__full-screen': isFullScreen },
          { modal__large: isLarge },
          { 'modal__input-content': hasInputContent },
          { 'modal__expanded-desktop-width': isDesktopWidthExpanded },
          { 'modal__no-footer': !hasFooter },
          { 'modal__no-scroll-bar': noScrollBar },
          { 'modal__space-around': hasSpaceAround }
        ]"
        role="dialog"
        aria-modal="true"
        tabindex="0"
        aria-label="modal"
        @keydown="checkKeyEvent"
        @keydown.esc="closeModal"
      >
        <section class="modal__content">
          <button
            v-if="showCloseIcon"
            class="modal__close-icon"
            aria-label="close"
            type="button"
            @click="closeModal"
          >
            <i class="icon icon-close"></i>
          </button>

          <section v-if="!noPadding" class="modal__header">
            <slot name="header"
              ><h2 v-if="header" class="modal__header-text">
                {{ header }}
              </h2></slot
            >
          </section>

          <section
            id="modal-body"
            class="modal__body"
            :class="[{ 'modal__body--no-padding': noPadding }]"
          >
            <slot name="body">
              <Article v-if="body" v-bind="body" no-margin />
            </slot>
            <Spinner v-if="loading" class="modal__loading" />
          </section>
        </section>

        <section v-if="hasFooter" class="modal__footer">
          <slot name="footer">
            <AppButton
              :class="{ 'modal__close-button': !showConfirmBtn }"
              variant="primary"
              type="button"
              @click="closeModal"
            >
              <slot name="cancelButton">{{ cancelBtnText }}</slot>
            </AppButton>
            <AppButton
              v-if="showConfirmBtn"
              variant="invertedPrimary"
              data-testid="modal_confirm-btn"
              @click="$emit('confirmed')"
            >
              {{ confirmBtnText }}
            </AppButton>
          </slot>
        </section>
      </div>
    </div>
  </transition>
</template>

<script>
import AppButton from "./AppButton"
import Article from "./Article"
import preventScrollMixin from "./../mixins/preventScrollMixin"
import Spinner from "./Spinner.vue"

export default {
  name: "Modal",
  components: {
    AppButton,
    Article,
    Spinner
  },
  mixins: [preventScrollMixin],
  props: {
    header: {
      type: String,
      default: ""
    },
    body: {
      type: [Object, Array],
      default: null
    },
    isModalOpen: {
      type: Boolean,
      default: false
    },
    showCloseIcon: {
      type: Boolean,
      default: true
    },
    showConfirmBtn: {
      type: Boolean,
      default: false
    },
    isFullScreen: {
      type: Boolean,
      default: false
    },
    isLarge: {
      type: Boolean,
      default: false
    },
    hasInputContent: {
      type: Boolean,
      default: false
    },
    isAutofocusDisabled: {
      type: Boolean,
      default: false
    },
    isDesktopWidthExpanded: {
      type: Boolean,
      default: false
    },
    hasFooter: {
      type: Boolean,
      default: true
    },
    noPadding: {
      type: Boolean,
      default: false
    },
    cancelBtnText: {
      type: String,
      default: "Cancel"
    },
    confirmBtnText: {
      type: String,
      default: "Confirm"
    },
    hasSpaceAround: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    noScrollBar: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    fullScreenAnimation() {
      return this.isFullScreen ? "slide" : null
    }
  },
  watch: {
    isModalOpen: {
      handler(value) {
        this.preventBodyScroll(value)
        if (!this.isAutofocusDisabled) {
          this.focusingModal()
        }
      },
      immediate: true
    }
  },
  methods: {
    closeModal() {
      this.$emit("closeModal")
    },
    checkKeyEvent(event) {
      const focusableList = this.$refs.modal.querySelectorAll(
        "button, [href], input, select, textarea"
      )
      if (focusableList.length < 2 && event.key === "Tab") {
        event.preventDefault()
        return
      }
      const last = focusableList.length - 1
      if (
        event.key === "Tab" &&
        event.shiftKey === false &&
        event.target === focusableList[last]
      ) {
        event.preventDefault()
        focusableList[0].focus()
      } else if (
        event.key === "Tab" &&
        event.shiftKey === true &&
        event.target === focusableList[0]
      ) {
        event.preventDefault()
        focusableList[last].focus()
      }
    },
    focusingModal() {
      return this.isModalOpen
        ? this.$nextTick(() => this.$refs.modal.focus())
        : null
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-backdrop {
  @include overlay;
  z-index: zindex(modal);
  opacity: 1;

  .modal {
    display: flex;
    flex-direction: column;
    background-color: $day;
    max-width: 358px;
    max-height: 100%;
    border-radius: 4px;
    margin: 0 $spacing-4;
    transition: all 0.3s ease;
    position: initial;
    height: auto;

    @include sm {
      max-height: 85%;
    }

    &__content {
      display: flex;
      flex-direction: column;
      position: relative;
      overflow: auto;
    }

    &__loading {
      position: absolute;
      display: flex;
      align-items: center;
      inset: 0;
      background-color: $day;
      z-index: zindex(modal-loading);
    }

    &__close-icon {
      position: absolute;
      right: 32px;
      top: 32px;
      border: 0;
      width: 24px;
      height: 24px;
      padding: 0;
      background-color: transparent;
      cursor: pointer;
      font-size: $size-6;
      line-height: 20px;

      .icon {
        font-weight: 500;
        line-height: 26px;
      }
    }

    &__header {
      margin: $spacing-8 $spacing-6 $spacing-4 $spacing-6;
    }

    &__header-text {
      font-size: $font-size-6;
    }

    &__body {
      padding: 0 $spacing-6 $spacing-6 $spacing-6;

      @include sm {
        overflow: auto;
      }

      &--no-padding {
        padding: 0;
      }
    }

    &__footer {
      background-color: $day;
      border-radius: 0 0 4px 4px;
      display: flex;

      ::v-deep button {
        flex: 1;

        &:focus:not(:active) {
          z-index: 1;
        }
        &:first-of-type {
          border-radius: 0 0 0 4px;
        }
        &:last-of-type {
          border-radius: 0 0 4px 0;
        }

        &:only-child {
          border-radius: 0 0 4px 4px;
        }
      }
    }

    &__close-button {
      width: 100%;
      border-radius: 0 0 4px 4px;
    }
  }

  .modal__close-icon-hide {
    .modal__header {
      margin-top: $spacing-7;
    }

    .modal__close-icon {
      display: none;
    }
  }

  .modal__full-screen {
    max-width: unset;
    width: 100%;
    margin: 0;
    height: 100%;
    overflow: auto;
    position: relative;
    border-radius: 0px;

    @include sm {
      max-width: 556px;
      width: unset;
      height: unset;
      overflow: hidden;
      border-radius: 4px;
    }

    .modal__content {
      padding-top: $spacing-7;

      @include sm {
        padding-top: 0;
      }
    }

    .modal__body {
      position: relative;
      margin-bottom: 3rem;

      @include sm {
        overflow: unset;
      }
    }

    &.modal__no-footer {
      .modal__body {
        margin-bottom: 0;
      }
    }

    .modal__footer {
      position: fixed;
      bottom: 0;
      width: 100%;

      @include sm {
        position: absolute;
      }
    }
  }

  .modal__input-content {
    .modal__close-icon {
      display: none;
    }

    .modal__header {
      margin: $spacing-7 $spacing-7 $spacing-4 $spacing-7;
    }

    .modal__body {
      padding: 0 $spacing-7 $spacing-7 $spacing-7;
    }
  }

  .modal__large {
    @include sm {
      max-width: unset;
      width: $width-4;
    }
  }

  .modal__expanded-desktop-width {
    @include lg {
      min-width: 900px;
    }

    .modal__content {
      padding-top: $spacing-6;
    }

    .modal__header {
      margin: 0 $spacing-4;

      @include sm {
        margin: 0 $spacing-6;
      }
    }

    .modal__close-icon {
      top: $spacing-6;
      right: $spacing-4;

      @include sm {
        right: $spacing-6;
      }

      @include lg {
        top: $spacing-6;
      }
    }

    .modal__body {
      padding: 0 $spacing-4;

      @include sm {
        padding: 0 $spacing-6;
      }

      @include lg {
        padding-bottom: 0;
      }
    }
  }

  .modal__space-around {
    padding: $space-6;
  }

  .modal__no-scroll-bar {
    .modal__body {
      scrollbar-width: none;
    }
  }
}

.slide-enter-active {
  transition: all 0.3s ease;
}
.slide-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-enter,
.slide-leave-to {
  transform: translateY(100vh);
  opacity: 0;
  min-height: 0px;
}

@include sm {
  .slide-enter-active,
  .slide-leave-active,
  .slide-enter,
  .slide-leave-to {
    transform: unset;
  }
}
</style>
