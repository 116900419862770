<template>
  <Modal
    :is-modal-open="show"
    :show-close-icon="modalSettings.showCloseButton"
    @close-modal="closeModal"
  >
    <template #header>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <h4 v-html="modalSettings.message" />
    </template>
    <template #footer>
      <AppButton
        :variant="modalSettings.showNavButton ? 'invertedPrimary' : 'primary'"
        @click="closeModal"
      >
        {{ modalSettings.closeBtnText || `Close` }}
      </AppButton>

      <AppButton v-if="modalSettings.showNavButton" @click="navigate">
        {{ modalSettings.navButtonText }}
      </AppButton>
    </template>
  </Modal>
</template>

<script>
import Modal from "@soenergy/frontend-library/src/components/Modal"
import AppButton from "@soenergy/frontend-library/src/components/AppButton"

export default {
  components: {
    Modal,
    AppButton,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    variation: {
      type: String,
      default: "generic", // generic, alreadyApplied
    },
  },
  emits: ["close"],
  data() {
    return {
      alreadyApplied: {
        message:
          "A Warm Home Discount Scheme application has already been submitted for this account number. Please contact us if you think something’s wrong.",
        showCloseButton: false,
        showNavButton: true,
        navButtonText: "View application status",
        navButtonRoute: "/status",
      },
      generic: {
        message:
          "Sorry, we’ve run into an unexpected error. Please try again. If the error persists, get in touch and we’ll investigate.",
        showCloseButton: true,
      },
    }
  },
  computed: {
    modalSettings() {
      return this[this.variation]
    },
  },
  methods: {
    closeModal() {
      this.$emit("close")
    },
    navigate() {
      this.$router.push(this.modalSettings.navButtonRoute)
    },
  },
}
</script>

<style scoped lang="scss"></style>
