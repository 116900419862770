<template>
  <section
    v-if="applicationDetailsLoaded && pageContent"
    class="status step-wrapper"
  >
    <div class="step-content">
      <h2 class="status__heading">{{ pageContent.title }}</h2>

      <p class="status__account-number status__text">
        {{ pageContent.accountNumberLabel }}
        <span ref="accountNumber">{{ accountNumber }}</span>
      </p>

      <p class="status__text">
        {{ pageContent.supplyAddressLabel }}
        <span ref="address">{{ address }}</span>
      </p>

      <h4 class="status__subheading">{{ pageContent.statusTitle }}</h4>
      <p ref="statusText">
        {{ applicationStatusText }}
      </p>

      <WarningModal
        :show="modal.isModalVisible"
        :variation="modal.variation"
        @close="modal.isModalVisible = false"
      />
    </div>

    <div class="step-nav-buttons">
      <AppButton class="step-nav-buttons__next" :href="websiteAccountUrl">
        <span>{{ pageContent.backBtnText }}</span>
      </AppButton>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex"

import ErrorReporter from "@soenergy/frontend-library/src/services/ErrorReporter"

import RailsService from "@/services/RailsService"
import WarningModal from "@/components/WarningModal.vue"
import AppButton from "@soenergy/frontend-library/src/components/AppButton"

import cmsPreviewMixin from "@soenergy/frontend-library/src/mixins/cmsPreviewMixin"
import statusContent from "soenergy-cms-loader!?path=whd/check-status"

export default {
  components: {
    WarningModal,
    AppButton,
  },
  mixins: [cmsPreviewMixin({ story: statusContent })],
  data: () => ({
    websiteAccountUrl: `${process.env.VUE_APP_ACCOUNT_APP_URL}`,
    statusDetails: null,
    modal: {
      isModalVisible: false,
      variation: "generic",
    },
  }),
  computed: {
    ...mapGetters(["address", "accountNumber", "applicationDetailsLoaded"]),
    applicationStatusText() {
      return this.statusDetails && this.statusDetails.text
        ? this.statusDetails.text
        : this.defaultText
    },
    defaultText() {
      return this.pageContent.defaultText
    },
    statusDetailsMap() {
      return {
        "application received": {
          text: this.pageContent.applicationReceived,
        },
        "review in progress": {
          text: this.pageContent.reviewInProgress,
        },
        "document verification": {
          text: this.pageContent.documentVerification,
        },
        rejected: {
          text: this.pageContent.rejected,
        },
        approved: {
          text: this.pageContent.approved,
        },
        paid: {
          text: this.pageContent.paid,
        },
      }
    },
  },
  async created() {
    this.updateLoading(true)

    try {
      const { data: responseData } = await RailsService.fetchApplication()
      const application = responseData.response
      this.statusDetails = this.statusDetailsMap[application.status]
    } catch (error) {
      this.modal.isModalVisible = true
      this.modal.variation = "generic"

      const errorType =
        error.response && error.response.data && error.response.data.error
      const unexpectedErrorOccurred = errorType !== "Application Not Found"

      if (unexpectedErrorOccurred) {
        ErrorReporter.report(error)
      }
    }
    this.updateLoading(false)

    if (!this.applicationDetailsLoaded) {
      await this.fetchApplicationDetails()
    }
  },
  methods: {
    ...mapActions({
      fetchCustomerDetails: "fetchCustomerDetails",
    }),
    ...mapMutations({
      updateLoading: "UPDATE_LOADING",
    }),
    async fetchApplicationDetails() {
      this.updateLoading(true)

      try {
        await this.fetchCustomerDetails()
      } catch (error) {
        this.modal.isModalVisible = true
        this.modal.variation = "generic"
        ErrorReporter.report(error)
      }
      this.updateLoading(false)
    },
  },
}
</script>

<style scoped lang="scss">
.status {
  padding: $spacing-7 $spacing-4;

  @include md {
    padding: $spacing-7;
  }

  &__heading {
    @include lg {
      font-size: $size-3;
      margin-bottom: 3rem;
    }
  }
  &__account-number {
    margin-bottom: $spacing-4;
  }
  &__text {
    span {
      font-weight: $weight-medium;
    }
  }
  &__subheading {
    margin-top: $spacing-7;
    @include lg {
      font-size: $spacing-6;
      margin: $spacing-7 0 $spacing-6 0;
    }
  }
}
</style>
