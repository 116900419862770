<template>
  <div
    class="form-group date-field-wrapper"
    role="group"
    :aria-labelledby="inputName"
  >
    <label :id="inputName" class="form-group__label date-field__label">
      {{ label }}
    </label>

    <div class="form-group__input-group date-field">
      <input
        v-model="day"
        class="form-group__input date-field__input"
        :class="{
          'form-group__input--error': hasError,
        }"
        type="text"
        inputmode="numeric"
        placeholder="DD"
        pattern="[0-9]*"
        required
        :aria-label="`${inputName} day`"
        :aria-invalid="hasError || null"
        @blur="emitBlur"
      />

      <input
        v-model="month"
        class="form-group__input date-field__input"
        :class="{
          'form-group__input--error': hasError,
        }"
        type="text"
        inputmode="numeric"
        placeholder="MM"
        pattern="[0-9]*"
        required
        :aria-label="`${inputName} month`"
        :aria-invalid="hasError || null"
        @blur="emitBlur"
      />

      <input
        v-model="year"
        class="form-group__input date-field__input"
        :class="{
          'form-group__input--error': hasError,
        }"
        type="text"
        inputmode="numeric"
        placeholder="YYYY"
        pattern="[0-9]*"
        :aria-label="`${inputName} year`"
        :aria-invalid="hasError || null"
        @blur="emitBlur"
      />
    </div>

    <p v-if="errorMessage" class="form-group__invalid-feedback">
      {{ errorMessage }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
    hasError: {
      type: Boolean,
      default: null,
    },
    errorMessage: {
      type: String,
      default: null,
    },
    inputName: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
  emits: ["input", "blur"],
  computed: {
    day: {
      get() {
        return this.value.day
      },
      set(value) {
        return this.updateDateSegment("day", value)
      },
    },
    month: {
      get() {
        return this.value.month
      },
      set(value) {
        return this.updateDateSegment("month", value)
      },
    },
    year: {
      get() {
        return this.value.year
      },
      set(value) {
        return this.updateDateSegment("year", value)
      },
    },
  },
  methods: {
    updateDateSegment(property, value) {
      this.$emit("input", {
        ...this.value,
        [property]: value,
      })
    },
    emitBlur(event) {
      const focusedOutsideOfElement =
        !event.relatedTarget || !this.$el.contains(event.relatedTarget)
      if (focusedOutsideOfElement) {
        this.$emit("blur")
      }
    },
  },
}
</script>

<style scoped lang="scss">
.date-field-wrapper {
  margin-bottom: $space-10;
}
.date-field {
  display: flex;

  &__input {
    padding-top: 0;
    width: 80px;

    &:not(:last-child) {
      margin-right: $space-6;

      @include md {
        margin-right: $space-4;
      }
    }
  }
  &__label {
    position: static;
    font-weight: $weight-medium;
  }
}
</style>
