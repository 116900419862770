<template>
  <ErrorLayout :button="button" :variant="'notFoundError'" />
</template>

<script>
import ErrorLayout from "@soenergy/frontend-library/src/components/ErrorLayout"

export default {
  components: {
    ErrorLayout,
  },
  data() {
    return {
      button: {
        path: `${process.env.VUE_APP_RAILS_API_URL}accounts`,
        label: "Back to account",
      },
    }
  },
}
</script>

<style scoped lang="scss">
.error.content {
  min-width: 100%;
}
</style>
