<template>
  <div class="progress-container">
    <ol
      class="progress-list"
      :class="{ 'progress-list--vertical': isVertical }"
    >
      <li
        v-for="(stage, index) in steps"
        :key="index"
        :class="[
          'progress-list__item',
          {
            'progress-list__item--past': index < furthestStepReached
          }
        ]"
      >
        <component
          :is="clickable ? `button` : `div`"
          :class="getButtonClasses(index, 'progress-list__item-content')"
          :aria-label="stepLabel(index, stage.description)"
          :disabled="index > furthestStepReached || null"
          @click="clickedStage(index)"
          @keydown.enter="clickedStage(index)"
          @keydown.space.prevent="clickedStage(index)"
        />
        <p
          v-if="isVertical && stage.description"
          class="progress-list__item-description"
        >
          {{ stage.description }}
        </p>
      </li>
    </ol>
    <h2 v-if="showTitle" class="h4 progress-title">
      {{ steps[currentStepNumber].title }}
    </h2>
  </div>
</template>

<script>
/**
To complete a step, set its `completed` flag to `true` and increment the value of the `furthestStepReached` prop.
**/
export default {
  props: {
    /**
      List of step objects, consisting of:
      <br>- `title` [String]: Paragraph displayed below the progress bar,
      <br>- `completed` [Boolean]: Flag determining whether to show a tick icon.
    */
    steps: {
      type: Array,
      required: true
    },
    /**
     * Index of the furthest step, to which the progress line should be extended
     */
    furthestStepReached: {
      type: Number,
      required: true
    },
    currentStepNumber: {
      type: Number,
      required: true
    },
    clickable: {
      type: Boolean,
      default: true
    },
    showTitle: {
      type: Boolean,
      default: true
    },
    isVertical: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getButtonClasses(index, base) {
      const classes = [base]
      if (
        this.furthestStepReached >= index &&
        this.currentStepNumber !== index
      ) {
        classes.push(base + "--past")
      }
      if (this.currentStepNumber === index) {
        classes.push(base + "--present")
      }
      if (this.steps[index].completed) {
        classes.push(base + "--completed")
      }
      if (this.clickable) {
        classes.push(base + "--clickable")
      }
      return classes
    },
    stepLabel(index, description) {
      const { completed, title } = this.steps[index]
      let state = ""
      let additionalText = ""
      if (index === this.currentStepNumber) {
        state = " - Active"
      } else if (completed) {
        state = " - Completed"
      }
      if (this.isVertical) {
        additionalText = " (" + description + ")"
      }
      return `Step ${index + 1}: ${title}${state}${additionalText}`
    },
    clickedStage(index) {
      this.$emit("clickStage", index)
    }
  }
}
</script>

<style scoped lang="scss">
$dot-size: $spacing-4;
$dot-size-desktop: $spacing-6;
$line-thickness: $spacing-1;
$line-thickness-desktop: $spacing-2;
.progress-title {
  margin-top: $spacing-5;
  @include md {
    margin-top: $spacing-6;
  }
}
.progress-list {
  display: flex;
  position: relative;
  padding-top: $dot-size;
  list-style-type: none;
  @include md {
    display: flex;
    margin: 0;
    padding-top: $dot-size-desktop;
  }
  &__item {
    position: relative;
    flex: 1;
    height: $line-thickness;
    border: 0;
    left: 2px;
    background-color: $day-dark;
    @include md {
      height: $line-thickness-desktop;
    }
    &--past {
      background-color: $water;
    }
    &:last-of-type {
      flex-grow: 0;
      flex-basis: $dot-size-desktop + $spacing-2;
      background-color: transparent;
    }
  }
  &__item-content {
    position: relative;
    display: block;
    width: $dot-size;
    height: $dot-size;
    margin-left: -2px;
    padding: 0;
    border: 2px solid $day-dark;
    border-radius: 50%;
    left: calc($dot-size / 2);
    top: calc($line-thickness / 2);
    transform: translate(-50%, -50%);
    background-color: $day-dark;
    @include md {
      width: $dot-size-desktop;
      height: $dot-size-desktop;
      border-width: $line-thickness-desktop;
      left: calc($dot-size-desktop / 2);
      top: calc($line-thickness-desktop / 2);
    }
    &--past {
      border-color: $water;
      background-color: $water;
    }
    &--present {
      border-color: $water;
      background-color: $water-light;
      @include md {
        height: $dot-size-desktop + $spacing-2;
        width: $dot-size-desktop + $spacing-2;
      }
    }
    &--completed:not(&--present) {
      &:before {
        @include font-icon($icon-check);
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: $day;
        font-size: 0.5rem;
        @include md {
          font-size: 1rem;
        }
      }
    }
    &--clickable {
      touch-action: manipulation;
      cursor: pointer;
      // Increases touch area for mobile
      &:after {
        position: absolute;
        content: "";
        height: 2em;
        width: 2em;
        transform: translate(-50%, -50%);
      }
      &:focus {
        outline: 0;
        box-shadow: 0px 0px 0px 2px $focus;
        @include md {
          box-shadow: 0px 0px 0px 4px $focus;
        }
      }
      &:hover.progress-list__item-content--past {
        border-color: $water-light;
        background-color: $water-light;
      }
      &:disabled {
        cursor: default;
        box-shadow: none;
      }
    }
  }
}
// additional css for vertical variation
.progress-list--vertical {
  flex-direction: column;
  margin-left: $spacing-3;
  padding-top: 0;
  .progress-list__item {
    height: 75px;
    min-height: 75px;
    border-left: $line-thickness-desktop solid $day-dark;
    margin-left: calc($line-thickness-desktop / 4);
    background-color: transparent;
    &--past {
      border-left: $line-thickness-desktop solid $water;
      background-color: transparent;
    }
    &:last-of-type {
      border-left: $line-thickness-desktop solid transparent;
    }
    @include md {
      height: 75px;
      min-height: 75px;
      border-left: $line-thickness-desktop solid $day-dark;
      margin-left: calc($line-thickness-desktop / 4);
      &--past {
        border-left: $line-thickness-desktop solid $water;
        background-color: transparent;
      }
    }
  }
  .progress-list__item-description {
    line-height: $size-6;
    padding-top: $spacing-1;
    padding-left: calc(
      $spacing-3 + $dot-size-desktop / 2 - $line-thickness-desktop / 2
    );
    @include lg {
      line-height: $size-5;
      padding-top: 0;
      font-size: $size-6;
    }
  }
  .progress-list__item-content {
    position: absolute;
    transform: translate(-50%, 0);
    width: $dot-size-desktop;
    height: $dot-size-desktop;
    border-width: $line-thickness-desktop;
    left: calc(-#{$line-thickness-desktop} / 4);
    top: -1px;
    @include md {
      left: calc(-#{$line-thickness-desktop} / 4);
      top: -1px;
    }
    &--completed:not(&--present) {
      &:before {
        font-size: 1rem;
      }
    }
    &--present {
      height: $dot-size-desktop + $spacing-2;
      width: $dot-size-desktop + $spacing-2;
    }
    &--present + .progress-list__item-description {
      padding-top: 6px;
      color: $water-dark;
      @include lg {
        padding-top: calc($spacing-1 / 2);
      }
    }
  }
}
</style>
