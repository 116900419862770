<template>
  <section
    v-if="isAuthenticated && applicationDetailsLoaded && pageContent"
    class="details"
  >
    <div class="step-content">
      <h2 class="details__heading">{{ pageContent.title }}</h2>
      <div class="generic-list">
        <ul class="details__account-info">
          <li>
            <p>
              {{ pageContent.accountNumberLabel }}
              <span ref="accountNumber">{{ accountNumber }}</span>
            </p>
          </li>
          <li>
            <p>
              {{ pageContent.supplyAddressLabel }}
              <span ref="address">{{ address }}</span>
            </p>
          </li>
          <li>
            <p>
              {{ pageContent.accountHolderLabel }}
              <span ref="name">{{ firstName }} {{ lastName }}</span>
            </p>
          </li>
        </ul>
      </div>

      <p class="details__account-holder">
        {{ pageContent.additionalInfo }}
      </p>

      <Checkbox
        id="consent-checkbox"
        v-model="consent"
        class="mb-2"
        :input-id="'consent'"
        :label="pageContent.consentCheckboxLabel"
      />
    </div>

    <div
      v-if="hasMultipleAccounts"
      ref="changeAccountButton"
      class="step-nav-buttons"
    >
      <a
        :href="websiteAccountSelectorUrl"
        class="button-cta button-cta--tertiary step-nav-buttons__back"
      >
        {{ pageContent.chooseAccountText }}
      </a>
    </div>

    <div class="step-nav-buttons">
      <AppButton
        variant="transparentSecondary"
        class="step-nav-buttons__back"
        :href="websiteAccountUrl"
      >
        <span class="icon icon-ctrl-left"></span>
        <span>{{ pageContent.backBtnText }}</span>
      </AppButton>
      <AppButton
        class="step-nav-buttons__next"
        variant="primary"
        :disabled="!consent"
        @click="goToEligibilityReminder"
      >
        <span>{{ pageContent.nextBtnText }}</span>
        <span class="icon icon-ctrl-right"></span>
      </AppButton>
    </div>

    <WarningModal
      ref="warning-modal"
      :show="modal.isModalVisible"
      :variation="modal.variation"
      @close="modal.isModalVisible = false"
    />
  </section>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex"
import ErrorReporter from "@soenergy/frontend-library/src/services/ErrorReporter"

import RailsService from "@/services/RailsService"
import WarningModal from "@/components/WarningModal"

import AppButton from "@soenergy/frontend-library/src/components/AppButton"
import Checkbox from "@soenergy/frontend-library/src/components/Checkbox"

import WHDEligibilityService from "@soenergy/frontend-library/src/services/WHDEligibilityService"
import cmsPreviewMixin from "@soenergy/frontend-library/src/mixins/cmsPreviewMixin"
import homeContent from "soenergy-cms-loader!?path=whd/home"

export default {
  components: {
    WarningModal,
    Checkbox,
    AppButton,
  },
  mixins: [cmsPreviewMixin({ story: homeContent })],
  data: () => ({
    websiteAccountSelectorUrl: `${process.env.VUE_APP_ACCOUNT_APP_URL}choose`,
    websiteAccountUrl: `${process.env.VUE_APP_ACCOUNT_APP_URL}`,
    consent: false,
    modal: {
      isModalVisible: false,
      variation: "generic",
    },
  }),
  computed: {
    ...mapGetters([
      "firstName",
      "lastName",
      "address",
      "email",
      "phoneNumber",
      "accountNumber",
      "hasMultipleAccounts",
      "applicationDetailsLoaded",
      "isAuthenticated",
      "accountId",
    ]),
  },
  async created() {
    if (!this.applicationDetailsLoaded) {
      await this.checkWHDStatus()
      await this.checkWHDEligibility()
      await this.fetchAndStoreApplicationDetails()
      await this.showErrorIfAlreadyApplied()
    }
  },
  methods: {
    ...mapActions({
      fetchCustomerDetails: "fetchCustomerDetails",
      fetchCriteria: "whdApplication/fetchCriteria",
    }),
    ...mapMutations({
      setCustomerCredentials: "SET_CUSTOMER_CREDENTIALS",
      updateLoading: "UPDATE_LOADING",
      setPersonalDetails: "whdApplication/SET_PERSONAL_DETAILS",
    }),
    goToEligibilityReminder() {
      this.$router.push("/eligibility")
    },
    async fetchAndStoreApplicationDetails() {
      this.updateLoading(true)

      try {
        await Promise.all([this.fetchCustomerDetails(), this.fetchCriteria()])
      } catch (error) {
        this.modal.isModalVisible = true
        this.modal.variation = "generic"
        ErrorReporter.report(error)
      }
      this.updateLoading(false)

      this.setPersonalDetails({
        firstName: this.firstName,
        lastName: this.lastName,
        phoneNumber: this.phoneNumber,
        email: this.email,
      })
    },
    async showErrorIfAlreadyApplied() {
      try {
        const response = await RailsService.fetchApplication()
        if (!response || !response.data) {
          throw "No response data"
        }
        const applicationAlreadyExists = response.data.success
        if (applicationAlreadyExists) {
          this.$router.push("status")
        }
      } catch (error) {
        const errorType =
          error.response && error.response.data && error.response.data.error
        const unexpectedErrorOccurred = errorType !== "Application Not Found"

        if (unexpectedErrorOccurred) {
          ErrorReporter.report(error)
        }
      }
    },
    async checkWHDStatus() {
      try {
        const response = await RailsService.getFeaturesStates()
        if (response && response.data) {
          if (
            !response.data.warmhomediscount &&
            this.$route.name !== "CheckStatus"
          ) {
            this.$router.push("applications-closed")
          }
        } else {
          throw "No response data"
        }
      } catch (error) {
        this.$router.push("unexpected-error")
        ErrorReporter.report(error)
      }
    },
    async checkWHDEligibility() {
      try {
        const eligible = await WHDEligibilityService.getWHDRegionEligibility(
          this.accountId
        )
        if (!eligible) {
          this.$router.push("england-and-wales-customers")
        }
      } catch (error) {
        this.modal.isModalVisible = true
        this.modal.variation = "generic"
        ErrorReporter.report(error)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.details {
  @include lg {
    position: relative;
  }

  &__heading {
    @include lg {
      font-size: $size-3;
      margin-bottom: 3rem;
    }
  }

  &__account-info {
    li {
      span {
        font-weight: $weight-medium;
      }
    }
  }
  &__account-holder {
    position: relative;
    margin-left: $spacing-4;
    margin-bottom: $spacing-6;
    padding-left: $spacing-4;
    font-size: $size-7;

    &:before {
      position: absolute;
      content: "\ea2f";
      font: normal bold normal 1.5rem/1 "Nucleo";
      line-height: inherit;
      transform: translate(-100%, 0);
      padding-right: $spacing-2;
      left: 1rem;

      @include lg {
        padding-right: $spacing-4;
        top: -4px;
      }
    }
  }
}
</style>
