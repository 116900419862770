<template>
  <section v-if="pageContent" class="error-screen step-wrapper">
    <div class="step-content">
      <h2 class="error-screen__heading">
        {{ pageContent.title }}
      </h2>
    </div>

    <div class="step-nav-buttons">
      <AppButton class="step-nav-buttons__next" :href="websiteAccountUrl">
        <span>{{ pageContent.backBtnText }}</span>
      </AppButton>
    </div>
  </section>
</template>

<script>
import AppButton from "@soenergy/frontend-library/src/components/AppButton"

import cmsPreviewMixin from "@soenergy/frontend-library/src/mixins/cmsPreviewMixin"
import errorContent from "soenergy-cms-loader!?path=whd/error-screen"

export default {
  components: {
    AppButton,
  },
  mixins: [cmsPreviewMixin({ story: errorContent })],
  data: () => ({
    websiteAccountUrl: `${process.env.VUE_APP_ACCOUNT_APP_URL}`,
  }),
}
</script>

<style lang="scss" scoped>
.error-screen {
  &__heading {
    margin-bottom: 0;

    @include lg {
      font-size: $size-3;
    }
  }
}
</style>
