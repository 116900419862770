<template>
  <section v-if="pageContent" class="summary step-wrapper">
    <div class="step-content">
      <p class="summary__text">
        {{ pageContent.accountNumberLabel }}
        <strong ref="accountNumber">{{ accountNumber }}</strong>
      </p>
      <p class="summary__text">
        {{ pageContent.supplyAddressLabel }}
        <strong ref="address">{{ address }}</strong>
      </p>

      <h4 class="summary__heading">
        {{ pageContent.detailsTitle }}
        <a class="summary__link" @click="openDetailsForm">Edit</a>
      </h4>
      <p class="summary__text">
        {{ pageContent.firstNameLabel }}
        <strong ref="firstName">{{ formData.firstName }}</strong>
      </p>
      <p class="summary__text">
        {{ pageContent.surnameLabel }}
        <strong ref="lastName">{{ formData.lastName }}</strong>
      </p>
      <p class="summary__text">
        {{ pageContent.emailLabel }}
        <strong ref="email">{{ formData.email }}</strong>
      </p>
      <p class="summary__text">
        {{ pageContent.phoneNumberLabel }}
        <strong ref="phoneNumber">{{ formData.phoneNumber }}</strong>
      </p>
      <p class="summary__text">
        {{ pageContent.dateBirthLabel }}
        <strong ref="birthday">{{ formattedBirthday }}</strong>
      </p>

      <h4 class="summary__heading">
        {{ pageContent.situationTitle }}
        <a class="summary__link" @click="openCriteriaSelector">Edit</a>
      </h4>
      <ul class="summary-list">
        <li ref="criteria1" class="summary-list__text">
          {{ primaryCriterionSelected }}
        </li>
        <li ref="criteria2" class="summary-list__text">
          {{ secondaryCriterionSelected }}
        </li>
      </ul>

      <h4 v-if="formData.uploadedFiles.length" class="summary__heading">
        {{ pageContent.proofTitle }}
        <a class="summary__link" @click="openProofSelector">Edit</a>
      </h4>
      <div v-if="formData.uploadedFiles.length" class="summary-uploaded">
        <div
          v-for="(uploadedFile, index) in formData.uploadedFiles"
          :key="index"
          class="summary-uploaded__file-wrapper"
          @click="previewFile(uploadedFile)"
        >
          <FeedbackDialog class="summary-uploaded__feedback" variant="positive">
            <span class="summary-uploaded__file-name">
              {{ successFeedback(uploadedFile.name) }}
            </span>
          </FeedbackDialog>
        </div>
      </div>

      <h4 class="summary__heading">
        {{ pageContent.communicationPreferenceTitle }}
      </h4>
      <RadioGroup
        v-model="communicationPreference"
        class="preference-radio-group mb-6"
      >
        <RadioButton
          is-label-small
          input-id="preference-email-radio"
          value="email"
          :label="pageContent.emailRadioBtnLabel"
          class="mr-4"
        />
        <RadioButton
          is-label-small
          input-id="preference-letter-radio"
          value="letter"
          :label="pageContent.letterRadioBtnLabel"
          class="mr-4"
        />
      </RadioGroup>

      <Checkbox
        id="confirm-checkbox"
        v-model="consent"
        class="mb-2 summary-confirmation"
        :input-id="'confirm'"
        :label="pageContent.consentLabel"
      />
    </div>

    <div class="step-nav-buttons">
      <AppButton
        variant="transparentSecondary"
        class="step-nav-buttons__back"
        @click="$emit('go-back')"
      >
        <span class="icon icon-ctrl-left"></span>
        <span>{{ pageContent.backBtnText }}</span>
      </AppButton>
      <AppButton
        class="step-nav-buttons__next"
        variant="primary"
        :loading="requestPending"
        :disabled="!consent || requestPending"
        @click="sendApplication()"
      >
        <span>{{ pageContent.nextBtnText }}</span>
        <span class="icon icon-ctrl-right"></span>
      </AppButton>
    </div>
    <WarningModal
      ref="warning-modal"
      :show="modal.isModalVisible"
      :variation="modal.variation"
      @close="modal.isModalVisible = false"
    />
    <Modal
      :is-full-screen="true"
      :is-modal-open="isFilePreviewVisible"
      @close-modal="isFilePreviewVisible = false"
    >
      <template #body>
        <FilePreview :file="currentFile" />
        <p class="file-modal-text">{{ fileModalText }}</p>
      </template>
      <template #cancelButton>Close</template>
    </Modal>
  </section>
</template>

<script>
import { mapGetters, mapState, mapMutations } from "vuex"
import ErrorReporter from "@soenergy/frontend-library/src/services/ErrorReporter"

import RailsService from "@/services/RailsService"
import WarningModal from "@/components/WarningModal.vue"
import AppButton from "@soenergy/frontend-library/src/components/AppButton"
import Checkbox from "@soenergy/frontend-library/src/components/Checkbox"
import FeedbackDialog from "@soenergy/frontend-library/src/components/FeedbackDialog"
import FilePreview from "@soenergy/frontend-library/src/components/FilePreview"
import Modal from "@soenergy/frontend-library/src/components/Modal"

import RadioGroup from "@soenergy/frontend-library/src/components/RadioButton/RadioGroup"
import RadioButton from "@soenergy/frontend-library/src/components/RadioButton/RadioButton"

import cmsPreviewMixin from "@soenergy/frontend-library/src/mixins/cmsPreviewMixin"
import summaryContent from "soenergy-cms-loader!?path=whd/summary"

export default {
  components: {
    AppButton,
    Checkbox,
    FeedbackDialog,
    FilePreview,
    Modal,
    RadioGroup,
    RadioButton,
    WarningModal,
  },
  emits: ["go-back", "go-specific"],
  mixins: [cmsPreviewMixin({ story: summaryContent })],
  data: () => ({
    consent: false,
    communicationPreference: "email",
    requestPending: false,
    modal: {
      isModalVisible: false,
      variation: "generic",
    },
    isFilePreviewVisible: false,
    currentFile: null,
  }),
  computed: {
    ...mapState({
      formData: (state) => state.whdApplication.form,
      accountId: (state) => state.accountId,
    }),
    ...mapGetters({
      address: "address",
      accountNumber: "accountNumber",
      primaryCriterionSelected: "whdApplication/primaryCriterionSelected",
      secondaryCriterionSelected: "whdApplication/secondaryCriterionSelected",
    }),
    formattedBirthday() {
      const { day, month, year } = this.formData.dateOfBirth
      return new Date(year, month - 1, day).toLocaleDateString("en-GB", {
        month: "short",
        day: "numeric",
        year: "numeric",
      })
    },
    fileModalText() {
      const fileName = this.currentFile ? this.currentFile.name : ""
      return "Uploaded file: " + fileName
    },
  },
  methods: {
    ...mapMutations({
      updateLoading: "UPDATE_LOADING",
    }),
    openDetailsForm() {
      this.$emit("go-specific", 0)
    },
    openCriteriaSelector() {
      this.$emit("go-specific", 1)
    },
    openProofSelector() {
      this.$emit("go-specific", 3)
    },
    async sendApplication() {
      const { day, month, year } = this.formData.dateOfBirth
      const monthPadded = String(month).padStart(2, "0")
      const dayPadded = String(day).padStart(2, "0")
      const dateOfBirth = `${year}-${monthPadded}-${dayPadded}`

      const requestPayload = {
        account_id: this.accountId,
        first_name: this.formData.firstName,
        last_name: this.formData.lastName,
        email: this.formData.email,
        phone_number: this.formData.phoneNumber,
        first_criteria_selection: parseInt(this.formData.criteria1, 10),
        second_criteria_selection: parseInt(this.formData.criteria2, 10),
        date_of_birth: dateOfBirth,
        communication_preference: this.communicationPreference,
      }

      this.updateLoading(true)
      this.requestPending = true
      try {
        const { data: responseData } = await RailsService.submitApplication(
          requestPayload
        )
        if (responseData.success) {
          this.$router.push("completed")
        } else {
          this.modal.isModalVisible = true
          this.modal.variation = "generic"
          ErrorReporter.report(new Error("Application submission error"), {
            "server response data": responseData,
          })
        }
      } catch (error) {
        const errorType =
          error.response && error.response.data && error.response.data.error

        if (errorType && errorType.includes("already has an application")) {
          this.modal.isModalVisible = true
          this.modal.variation = "alreadyApplied"
        } else {
          this.modal.isModalVisible = true
          this.modal.variation = "generic"
          ErrorReporter.report(error)
        }
      }
      this.requestPending = false
      this.updateLoading(false)
    },
    successFeedback(fileName) {
      let text = "Uploaded file: "
      return fileName ? text + fileName : ""
    },
    previewFile(file) {
      this.currentFile = null

      if (file) {
        this.isFilePreviewVisible = true
        this.$nextTick(() => (this.currentFile = file))
      }
    },
  },
}
</script>

<style scoped lang="scss">
.summary__heading {
  font-size: $size-6;
  margin-top: $spacing-5;
  margin-bottom: $spacing-2;
  display: flex;
  align-items: center;
  font-weight: $weight-medium;

  @include lg {
    font-size: $size-5;
    margin-bottom: $spacing-5;
  }
}

.summary__link {
  font-size: $size-6;
  margin-left: $spacing-5;
}

.summary__text {
  margin-bottom: $spacing-2;
}

.summary-list {
  padding-left: $spacing-4;
  list-style-type: disc;

  &__text {
    margin-bottom: $spacing-3;
  }
}

.summary-confirmation :deep(label) {
  font-size: $size-7;
}

.summary-uploaded {
  margin-bottom: $spacing-9;

  &__file-wrapper {
    display: inline-block;
    cursor: pointer;
  }
}

.file-modal-text {
  font-size: $font-size-2;
  margin-top: $space-4;
}

.preference-radio-group :deep .radio-group__form {
  display: inline-flex;
}
</style>
