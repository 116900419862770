<template>
  <section class="criteria step-wrapper">
    <div class="step-content">
      <div class="criteria__text">
        <slot />
      </div>

      <fieldset class="criteria__radio radio-button radio-button--stacked">
        <RadioGroup v-model="selectedCriterion" :options="criteriaOptions" />
      </fieldset>
    </div>

    <div class="step-nav-buttons">
      <AppButton
        class="step-nav-buttons__back"
        variant="transparentSecondary"
        @click="$emit('go-back')"
      >
        <span class="icon icon-ctrl-left"></span>
        <span>Back</span>
      </AppButton>
      <AppButton
        class="step-nav-buttons__next"
        :disabled="!selectedCriterion"
        @click="submitted"
      >
        <span>Next</span>
        <span class="icon icon-ctrl-right"></span>
      </AppButton>
    </div>
  </section>
</template>

<script>
const INELIGIBLE_CRITERION_ID = "ineligible"
import AppButton from "@soenergy/frontend-library/src/components/AppButton"
import { RadioGroup } from "@soenergy/frontend-library/src/components/RadioButton"

export default {
  components: {
    AppButton,
    RadioGroup,
  },
  props: {
    criteria: {
      type: Array,
      default: () => [],
    },
    initialValue: {
      type: String,
      default: null,
    },
  },
  emits: ["go-back", "go-forward"],
  data: () => ({
    selectedCriterion: null,
  }),
  computed: {
    criteriaOptions() {
      const criteriaWithIneligibleOption = [
        ...this.criteria,
        { id: INELIGIBLE_CRITERION_ID, text: "None of the above" },
      ]
      return criteriaWithIneligibleOption.map((item) => {
        const newItem = {}
        newItem.value = item.id.toString()
        newItem.label = item.text
        newItem.inputId = "radio-" + item.id
        newItem.secondary_whd_criteria = item.secondary_whd_criteria
        return newItem
      })
    },
  },
  mounted() {
    this.selectedCriterion = this.initialValue
  },
  methods: {
    submitted() {
      const userIneligible = this.selectedCriterion === INELIGIBLE_CRITERION_ID

      if (userIneligible) {
        this.$router.push("ineligible")
        return
      }
      this.$emit("go-forward", this.selectedCriterion)
    },
  },
}
</script>
