import store from "@/store"
import HttpClient from "@soenergy/frontend-library/src/services/http/HttpClient"
import authenticate from "@soenergy/frontend-library/src/services/http/requestInterceptors/authenticate"
import reportError from "@soenergy/frontend-library/src/services/http/responseErrorInterceptors/reportError"

const apiClient = HttpClient({
  baseURL: process.env.VUE_APP_RAILS_API_URL,
  requestInterceptors: [authenticate],
  responseErrorInterceptors: [reportError],
})

const uploaderClient = HttpClient({
  requestInterceptors: [authenticate],
  responseErrorInterceptors: [reportError],
  headers: {
    "Content-Type": "application/octet-stream",
    "Cache-Control": "no-cache",
  },
})

export default {
  getFeaturesStates() {
    return apiClient.get("api/v1/features", {
      skipAuthentication: true,
    })
  },
  fetchCustomerDetails() {
    return apiClient.get(
      `api/v1/accounts/${store.state.accountId}/warm_home_discount_prospective_details`
    )
  },
  fetchCriteria() {
    return apiClient.get(`api/v1/warm_home_discount/criteria`)
  },
  fetchApplication() {
    return apiClient.get(
      `api/v1/accounts/${store.state.accountId}/warm_home_discount`
    )
  },
  submitApplication(application) {
    return apiClient.post(
      `api/v1/accounts/${store.state.accountId}/warm_home_discount`,
      application
    )
  },
  requestImageUpload(accountId, imageName) {
    return apiClient.get(
      `api/v1/accounts/${accountId}/warm_home_discount/psr/upload_image/${imageName}`
    )
  },
  uploadImage(uploadUrl, image) {
    return uploaderClient.put(uploadUrl, image)
  },
  deleteImage(accountId, imageName) {
    return apiClient.delete(
      `api/v1/accounts/${accountId}/warm_home_discount/psr/upload_image/${imageName}`
    )
  },
}
