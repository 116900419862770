import store from "@/store"

export default (to, from, next) => {
  const isProtectedRoute = to.matched.some((record) => record.meta.authenticate)
  if (isProtectedRoute && !store.getters.isAuthenticated) {
    store.commit("SET_AUTH_ERROR", true)
    return
  }

  next()
}
