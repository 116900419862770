import RailsService from "../../services/RailsService"
import AuthenticationService from "@soenergy/frontend-library/src/services/AuthenticationService"

export const untestedMutations = {
  SET_CUSTOMER_CREDENTIALS(state, accountId) {
    state.accountId = accountId
  },
  SET_EMAIL(state, email) {
    if (!state.customerDetails) {
      state.customerDetails = {}
    }
    state.customerDetails.email = email
  },
  SET_CUSTOMER_DETAILS(state, customerDetails) {
    state.customerDetails = {
      ...state.customerDetails,
      ...customerDetails,
    }
  },
  SET_AUTH_ERROR(state, show) {
    state.showAuthError = show
  },
  UPDATE_LOADING(state, isLoading) {
    state.loading = isLoading
  },
}

export const untestedActions = {
  async fetchCustomerDetails({ commit }) {
    const response = await RailsService.fetchCustomerDetails()
    if (response?.data) {
      const customerDetails = response.data
      commit("SET_CUSTOMER_DETAILS", customerDetails)

      const accountExists = Boolean(customerDetails)
      if (!accountExists) {
        commit("SET_AUTH_ERROR", true)
      }
    }
  },
  async logout() {
    await AuthenticationService.logout(true)
    window.location.assign(process.env.VUE_APP_WEBSITE_URL)
  },
}

export const untestedGetters = {
  applicationDetailsLoaded(state, rootGetters) {
    return (
      state.customerDetails !== null &&
      rootGetters["whdApplication/criteriaLoaded"]
    )
  },
  firstName(state) {
    if (!state.customerDetails) {
      return null
    }
    return state.customerDetails.first_name
  },
  lastName(state) {
    if (!state.customerDetails) {
      return null
    }
    return state.customerDetails.last_name
  },
  fullName(state) {
    if (!state.customerDetails) {
      return null
    }
    return `${state.customerDetails.first_name} ${state.customerDetails.last_name}`
  },
  hasMultipleAccounts(state) {
    if (!state.customerDetails) {
      return null
    }
    return state.customerDetails.has_multiple_account
  },
  phoneNumber(state) {
    if (!state.customerDetails) {
      return null
    }
    return state.customerDetails.phone_number
  },
  email(state) {
    if (!state.customerDetails) {
      return null
    }
    return state.customerDetails.email
  },
  accountNumber(state) {
    if (!state.customerDetails) {
      return null
    }
    return state.customerDetails.account_number
  },
  accountId(state) {
    if (!state.accountId) {
      return null
    }
    return state.accountId
  },
  address(state) {
    if (!state.customerDetails) {
      return null
    }
    const multilineAddressString = state.customerDetails.address
    if (!multilineAddressString) {
      return null
    }
    return multilineAddressString.replace(/\n/g, ", ")
  },
  isAuthenticated(state) {
    return Boolean(state.accountId)
  },
}
