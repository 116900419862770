<template>
  <Modal
    :is-modal-open="show"
    :show-close-icon="false"
    data-testid="login-modal"
  >
    <template #header>
      <p>
        To apply for the Warm Home Discount Scheme or view your application
        status, please log in.
      </p>
    </template>
    <template #footer>
      <AppButton id="login-modal__button" :href="websiteWhdRedirectUrl">
        Log in
      </AppButton>
    </template>
  </Modal>
</template>

<script>
import Modal from "@soenergy/frontend-library/src/components/Modal"
import AppButton from "@soenergy/frontend-library/src/components/AppButton"

export default {
  components: {
    Modal,
    AppButton,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    websiteWhdRedirectUrl: `${process.env.VUE_APP_ACCOUNT_APP_URL}?redirect=fe-whd`,
  }),
}
</script>

<style scoped lang="scss">
#login-modal__button {
  width: 100%;
  border-radius: 0 0 4px 4px;
}
</style>
