import RailsService from "../../../services/RailsService"

export const untestedActions = {
  async fetchCriteria({ commit }) {
    const response = await RailsService.fetchCriteria()
    if (response && response.data) {
      commit("SET_CRITERIA", response.data)
    }
  },
}

export const untestedMutations = {
  SET_STEP_COMPLETED(state, data) {
    state.steps[data.page].completed = data.value
  },
  SET_CRITERIA(state, criteria) {
    state.primaryCriteriaArray = criteria
    state.secondaryCriteriaMap = criteria.reduce(
      (map, { id, secondary_whd_criteria }) => ({
        ...map,
        [id]: secondary_whd_criteria,
      }),
      {}
    )
  },
  SET_PERSONAL_DETAILS(state, personalDetails) {
    state.form = {
      ...state.form,
      ...personalDetails,
    }
  },
  SET_PRIMARY_CRITERION(state, criterion) {
    state.form.criteria1 = criterion
  },
  SET_SECONDARY_CRITERION(state, criterion) {
    state.form.criteria2 = criterion
  },
  SET_UPLOADED_FILES(state, uploadedFiles) {
    state.form.uploadedFiles = uploadedFiles
  },
}

export const untestedGetters = {
  secondaryCriteriaArray: (state) => {
    const selectedId = state.form.criteria1
    return state.secondaryCriteriaMap[selectedId]
  },
  primaryCriterionSelected: ({ form, primaryCriteriaArray }) => {
    const selectedId = form.criteria1
    return primaryCriteriaArray.find((criterion) => criterion.id == selectedId)
      .text
  },
  secondaryCriterionSelected: ({ form }, { secondaryCriteriaArray }) => {
    const selectedId = form.criteria2
    return secondaryCriteriaArray.find(
      (criterion) => criterion.id == selectedId
    ).text
  },
}
