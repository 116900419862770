<template>
  <section v-if="pageContent" class="personal-details step-wrapper">
    <form class="step-content">
      <Article :text-content="descriptionText" />

      <InputField
        id="first-name"
        ref="first-name"
        v-model="form.firstName"
        name="first-name"
        label="First name"
        :error-message="form.firstName ? '' : 'Please enter a valid first name'"
        :has-error="!form.firstName"
        :aria-invalid="!form.firstName"
        :aria-describedBy="!form.firstName ? `firstname-error` : null"
        required
      />

      <InputField
        id="last-name"
        ref="last-name"
        v-model="form.lastName"
        name="last-name"
        label="Surname"
        :error-message="form.lastName ? '' : 'Please enter a valid last name'"
        :has-error="!form.lastName"
        :aria-invalid="!form.lastName"
        :aria-describedBy="!form.lastName ? `lastname-error` : null"
        required
      />

      <InputField
        id="email"
        ref="email"
        v-model="form.email"
        name="email"
        label="Email"
        :error-message="
          isValidEmail ? '' : 'Please enter a valid email address'
        "
        :has-error="!isValidEmail"
        :aria-invalid="!isValidEmail"
        :aria-describedBy="!isValidEmail ? `email-error` : null"
        required
      />

      <InputField
        id="phone-number"
        ref="phone-number"
        v-model="form.phoneNumber"
        name="phone-number"
        label="Phone number"
        :error-message="
          isValidPhone ? '' : 'Please enter a valid telephone number'
        "
        :has-error="!isValidPhone"
        :aria-invalid="!isValidPhone"
        :aria-describedBy="!isValidPhone ? `phone-error` : null"
        required
        type="tel"
      />

      <DateInput
        ref="date-input"
        v-model="form.dateOfBirth"
        :has-error="showBirthdayFieldError"
        :error-message="birthdayErrorMessage"
        input-name="birthday"
        label="Date of birth"
        @input="touchBirthdayFieldIfPopulated"
        @blur="touchBirthdayField"
      />
    </form>
    <div class="step-nav-buttons">
      <AppButton
        variant="transparentSecondary"
        class="step-nav-buttons__back"
        @click="$emit('go-back')"
      >
        <span class="icon icon-ctrl-left"></span>
        <span>{{ pageContent.backBtnText }}</span>
      </AppButton>
      <AppButton
        class="step-nav-buttons__next"
        variant="primary"
        :disabled="!isFormValid"
        @click="submitForm"
      >
        <span>{{ pageContent.nextBtnText }}</span>
        <span class="icon icon-ctrl-right"></span>
      </AppButton>
    </div>
  </section>
</template>

<script>
import { mapMutations, mapState } from "vuex"
import DateInput from "@/components/DateInput"

import useVuelidate from "@vuelidate/core"
import { required, email, minLength } from "@vuelidate/validators"

import AppButton from "@soenergy/frontend-library/src/components/AppButton"
import Article from "@soenergy/frontend-library/src/components/Article"
import InputField from "@soenergy/frontend-library/src/components/InputField"

import cmsPreviewMixin from "@soenergy/frontend-library/src/mixins/cmsPreviewMixin"
import detailsContent from "soenergy-cms-loader!?path=whd/update-details"

export default {
  components: {
    DateInput,
    AppButton,
    InputField,
    Article,
  },
  mixins: [cmsPreviewMixin({ story: detailsContent })],
  emits: ["go-back", "go-forward"],
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
  data() {
    return {
      birthdayFieldDirty: false,
      form: {
        firstName: null,
        lastName: null,
        email: null,
        phoneNumber: null,
        dateOfBirth: null,
      },
    }
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
      phoneNumber: {
        minLength: minLength(10),
        required,
      },
    },
  },
  computed: {
    ...mapState("whdApplication", {
      initialFormData: "form",
    }),
    descriptionText() {
      return this.pageContent.description[0] &&
        this.pageContent.description[0].textContent
        ? this.pageContent.description[0].textContent
        : {}
    },
    isValidPhone() {
      return !this.v$.form.phoneNumber.$invalid
    },
    isValidEmail() {
      return !this.v$.form.email.$invalid
    },
    isBirthdayValid() {
      return this.isValidDate(this.form.dateOfBirth)
    },
    isBirthdayFieldPopulated() {
      const { day, month, year } = this.form.dateOfBirth
      return day && month && year && year.length === 4
    },
    showBirthdayFieldError() {
      return this.birthdayFieldDirty && !this.isBirthdayValid
    },
    birthdayErrorMessage() {
      if (!this.showBirthdayFieldError) {
        return null
      }
      return "Please enter a valid date of birth"
    },
    isFormValid() {
      return (
        this.form.firstName &&
        this.form.lastName &&
        this.isValidEmail &&
        this.isValidPhone &&
        this.isBirthdayValid
      )
    },
  },
  created() {
    this.form = {
      ...this.form,
      ...this.initialFormData,
    }
  },
  methods: {
    ...mapMutations("whdApplication", {
      setPersonalDetails: "SET_PERSONAL_DETAILS",
    }),
    isValidDate({ day, month, year }) {
      const MAX_AGE = 150
      const date = new Date(year, --month, day)
      const maxDate = new Date()
      const minDate = new Date()
      minDate.setFullYear(minDate.getFullYear() - MAX_AGE)

      if (date < minDate || date > maxDate) {
        return false
      }

      // Implicit coercion with loose equality operator
      const isCalendarDate =
        month == date.getMonth() && year == date.getFullYear()

      return isCalendarDate
    },
    touchBirthdayField() {
      this.birthdayFieldDirty = true
    },
    touchBirthdayFieldIfPopulated() {
      if (this.isBirthdayFieldPopulated) {
        this.touchBirthdayField()
      }
    },
    submitForm() {
      this.setPersonalDetails(this.form)
      this.$emit("go-forward")
    },
  },
}
</script>

<style scoped lang="scss">
.personal-details {
  &__text {
    margin-bottom: 32px;
  }
}
</style>
