import ErrorReporter from "../../ErrorReporter"

export default error => {
  const { skipErrorReporting, reportedErrorCodes = [500] } = error.config
  const serverResponse = error.response

  if (!skipErrorReporting && serverResponse) {
    const shouldReportError =
      !reportedErrorCodes || reportedErrorCodes.includes(serverResponse.status)

    if (shouldReportError) {
      const serverResponseData = serverResponse && serverResponse.data

      ErrorReporter.report(error, {
        ...(serverResponse ? { "server response": serverResponse } : {}),
        ...(serverResponseData
          ? { "server response data": serverResponseData }
          : {})
      })
    }
  }

  return Promise.reject(error)
}
