<template>
  <section v-if="pageContent" class="eligibility">
    <div class="step-content">
      <h2 class="eligibility__heading">{{ pageContent.title }}</h2>

      <Article :text-content="eligibilityText" />

      <div class="eligibility__checkbox checkbox-input">
        <Checkbox
          id="consent-checkbox"
          v-model="consent"
          :input-id="'consent'"
          :label="pageContent.consentLabel"
        />
      </div>
    </div>

    <div class="step-nav-buttons">
      <AppButton
        variant="transparentSecondary"
        class="step-nav-buttons__back"
        @click="goToConfirmDetails"
      >
        <span class="icon icon-ctrl-left"></span>
        <span>{{ pageContent.backBtnText }}</span>
      </AppButton>
      <AppButton
        class="step-nav-buttons__next"
        variant="primary"
        :disabled="!consent"
        @click="startApplication"
      >
        <span>{{ pageContent.nextBtnText }}</span>
        <span class="icon icon-ctrl-right"></span>
      </AppButton>
    </div>
  </section>
</template>

<script>
import AppButton from "@soenergy/frontend-library/src/components/AppButton"
import Checkbox from "@soenergy/frontend-library/src/components/Checkbox"
import Article from "@soenergy/frontend-library/src/components/Article"

import cmsPreviewMixin from "@soenergy/frontend-library/src/mixins/cmsPreviewMixin"
import eligibilityContent from "soenergy-cms-loader!?path=whd/eligibility"

export default {
  components: {
    AppButton,
    Checkbox,
    Article,
  },
  data() {
    return {
      consent: false,
    }
  },
  mixins: [cmsPreviewMixin({ story: eligibilityContent })],
  computed: {
    eligibilityText() {
      return this.pageContent.description[0] &&
        this.pageContent.description[0].textContent
        ? this.pageContent.description[0].textContent
        : {}
    },
  },
  methods: {
    startApplication() {
      this.$router.push("details")
    },
    goToConfirmDetails() {
      this.$router.push("/")
    },
  },
}
</script>

<style scoped lang="scss">
.eligibility {
  &__heading {
    margin-bottom: $spacing-4;

    @include lg {
      margin-bottom: $spacing-7;
      font-size: $size-3;
    }
  }

  &__text {
    margin-bottom: $spacing-6;

    &:last-of-type {
      margin-bottom: $spacing-8;

      @include lg {
        margin-bottom: $spacing-6;
      }
    }
  }
}
</style>
