<template>
  <CriteriaSelector
    v-if="pageContent"
    :criteria="primaryCriteriaArray"
    :initial-value="form.criteria1"
    v-bind="$attrs"
    @go-forward="updateCriterion"
    @go-back="$emit('go-back')"
  >
    <Article :text-content="criteriaDescription" />
  </CriteriaSelector>
</template>

<script>
import CriteriaSelector from "@/components/CriteriaSelector"
import Article from "@soenergy/frontend-library/src/components/Article"
import { mapMutations, mapState } from "vuex"

import cmsPreviewMixin from "@soenergy/frontend-library/src/mixins/cmsPreviewMixin"
import criteria1Content from "soenergy-cms-loader!?path=whd/criteria1"

export default {
  components: {
    CriteriaSelector,
    Article,
  },
  emits: ["go-back", "go-forward"],
  mixins: [cmsPreviewMixin({ story: criteria1Content })],
  computed: {
    ...mapState("whdApplication", ["form", "primaryCriteriaArray"]),
    criteriaDescription() {
      return this.pageContent.description[0] &&
        this.pageContent.description[0].textContent
        ? this.pageContent.description[0].textContent
        : {}
    },
  },
  methods: {
    ...mapMutations("whdApplication", {
      setPrimaryCriterion: "SET_PRIMARY_CRITERION",
      setSecondaryCriterion: "SET_SECONDARY_CRITERION",
    }),
    updateCriterion(selected) {
      this.setPrimaryCriterion(selected)
      this.setSecondaryCriterion(null)
      this.$emit("go-forward", selected)
    },
  },
}
</script>
