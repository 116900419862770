<template>
  <form class="file-uploader">
    <label class="file-uploader__label">{{ uploadTitle }}</label>

    <AppButton
      class="file-uploader__button"
      :class="{
        'file-uploader__button--disabled': isUploadDisabled
      }"
      variant="secondary"
      type="submit"
      size="large"
      :disabled="isUploadDisabled"
      :loading="state === states.LOADING"
    >
      <span class="icon icon-open"></span>
      <input
        :id="id"
        ref="fileInput"
        class="file-uploader__attachment-input"
        :name="id"
        type="file"
        :accept="acceptedFileList"
        :disabled="isUploadDisabled"
        @change="newUploadedFile = $event.target.files[0]"
      />
      <label class="file-uploader__attachment-label" :for="id">
        {{ uploadBtnLabel }}
      </label>
    </AppButton>

    <p v-if="state === states.MISSING_FILE" class="file-uploader__notification">
      {{ missingFileMessage }}
    </p>

    <FeedbackDialog
      v-if="isErrorMessageDisplayed"
      class="file-uploader__feedback"
      :message="errorFeedback"
      variant="negative"
    />
    <p class="file-uploader__message">
      {{ errorMessage }}
    </p>

    <div v-if="uploadedFiles.length" class="file-uploader__feedback-wrapper">
      <FeedbackDialog
        v-for="(uploadedFile, index) in uploadedFiles"
        :key="index"
        class="file-uploader__feedback"
        variant="positive"
      >
        <span class="file-uploader__file-name">{{
          successFeedback(uploadedFile.name)
        }}</span>

        <span
          class="file-uploader__removal"
          @click="deleteFile(uploadedFile, index)"
          ><span class="icon icon-close"></span
        ></span>
      </FeedbackDialog>
    </div>
  </form>
</template>

<script>
import AppButton from "./AppButton"
import FeedbackDialog from "./FeedbackDialog"

const STATES = Object.freeze({
  MISSING_FILE: 1,
  INVALID_SIZE: 2,
  LOADING: 3,
  SUCCESS: 4,
  FAILED: 5
})

export default {
  components: {
    AppButton,
    FeedbackDialog
  },
  props: {
    uploadedFiles: {
      type: Array,
      default: () => []
    },
    serverError: {
      type: String,
      default: ""
    },
    // configurable
    fileExtensions: {
      type: Array,
      default: () => []
    },
    uploadFileQuantity: {
      type: Number,
      default: 1
    },
    uploadFileSizeBytes: {
      type: Number,
      default: 20971520 // 20mb
    },
    uploadTitle: {
      type: String,
      default: ""
    },
    uploadBtnLabel: {
      type: String,
      default: ""
    },
    invalidSizeError: {
      type: String,
      default: ""
    },
    missingFileMessage: {
      type: String,
      default: ""
    }
  },
  emits: ["upload", "remove"],
  data: () => ({
    newUploadedFile: null,
    states: STATES,
    state: STATES.MISSING_FILE
  }),
  computed: {
    acceptedFileList() {
      return this.fileExtensions.toString()
    },
    errorFeedback() {
      let text = "File not uploaded: "
      return this.newUploadedFile ? text + this.newUploadedFile.name : ""
    },
    errorMessage() {
      if (this.state === this.states.INVALID_SIZE) {
        return this.invalidSizeError
      } else if (this.state === this.states.FAILED) {
        return this.serverError
      } else {
        return ""
      }
    },
    isErrorMessageDisplayed() {
      return (
        this.state === this.states.FAILED ||
        this.state === this.states.INVALID_SIZE
      )
    },
    isUploadDisabled() {
      return (
        this.state === this.states.LOADING ||
        this.uploadedFiles.length >= this.uploadFileQuantity
      )
    },
    id() {
      return `input-csv-${this._uid}`
    }
  },
  watch: {
    newUploadedFile(file) {
      if (file && this.validateFile(file)) {
        this.state = this.states.LOADING
        this.$emit("upload", file)
      }
    },
    uploadedFiles(files) {
      if (files.length) {
        this.state = this.states.SUCCESS
      } else {
        this.state = this.states.MISSING_FILE
      }
    },
    serverError() {
      this.state = this.states.FAILED
    }
  },
  methods: {
    validateFile(file) {
      if (file.size > this.uploadFileSizeBytes) {
        this.state = this.states.INVALID_SIZE
        return false
      }
      return true
    },
    successFeedback(fileName) {
      let text = "Uploaded file: "
      return fileName ? text + fileName : ""
    },
    deleteFile(file, index) {
      this.$emit("remove", { file, index })
    }
  }
}
</script>

<style lang="scss" scoped>
.file-uploader {
  &__label {
    display: block;
    font-weight: $weight-medium;
    margin-bottom: $space-6;
  }

  &__message,
  &__notification {
    font-size: $font-size-3;
    line-height: 24px;
    margin-top: $space-4;
  }

  &__message {
    color: $red-800;
  }

  &__button {
    &--disabled {
      .file-uploader__attachment-label,
      .file-uploader__attachment-input {
        cursor: default;
      }
    }
  }

  &__attachment-label {
    cursor: pointer;
  }

  &__attachment-input {
    cursor: pointer;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    opacity: 0;
    top: 0;
    z-index: 1;
  }

  &__feedback-wrapper {
    position: relative;
  }

  &__file-name {
    margin-right: $space-8;
    display: inline-block;
  }

  &__removal {
    position: absolute;
    padding: $space-3;
    top: 4px;
    right: 2px;
    cursor: pointer;

    .icon {
      font-size: $font-size-5;
      font-weight: $weight-bold;
    }
  }
}
</style>
