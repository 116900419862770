import store from "@/store"
import { identifyUser } from "@soenergy/frontend-library/src/services/datadog"

export default () => {
  const id = store.getters.accountNumber
  const email = store.getters.primaryEmail
  const name = store.getters.fullName

  identifyUser({
    id,
    email,
    name,
  })
}
