<template>
  <section v-if="pageContent" class="england-wales-screen step-wrapper">
    <div class="step-content">
      <h2 class="england-wales-screen__heading">{{ pageContent.title }}</h2>

      <Article :text-content="englandWalesText" />
    </div>

    <div class="step-nav-buttons">
      <AppButton :href="websiteAccountUrl">
        <span>{{ pageContent.backBtnText }}</span>
      </AppButton>
    </div>
  </section>
</template>

<script>
import AppButton from "@soenergy/frontend-library/src/components/AppButton"
import Article from "@soenergy/frontend-library/src/components/Article"

import cmsPreviewMixin from "@soenergy/frontend-library/src/mixins/cmsPreviewMixin"
import englandWalesContent from "soenergy-cms-loader!?path=whd/england-wales-screen"

export default {
  components: {
    AppButton,
    Article,
  },
  mixins: [cmsPreviewMixin({ story: englandWalesContent })],
  data: () => ({
    websiteAccountUrl: `${process.env.VUE_APP_ACCOUNT_APP_URL}`,
  }),
  computed: {
    englandWalesText() {
      return this.pageContent.description &&
        this.pageContent.description[0] &&
        this.pageContent.description[0].textContent
        ? this.pageContent.description[0].textContent
        : {}
    },
  },
}
</script>

<style lang="scss" scoped>
.england-wales-screen {
  &__heading {
    margin-bottom: $space-10;

    @include lg {
      font-size: $size-3;
    }
  }
}
</style>
