import {
  untestedActions,
  untestedGetters,
  untestedMutations,
} from "@/store/untested/modules/whdApplication"

export const namespaced = true

export const state = {
  primaryCriteriaArray: null,
  secondaryCriteriaMap: null,
  form: {
    firstName: null,
    lastName: null,
    phoneNumber: null,
    email: null,
    dateOfBirth: {
      day: null,
      year: null,
      month: null,
    },
    criteria1: null,
    criteria2: null,
    uploadedFiles: [],
  },
  steps: [
    {
      name: "UpdateDetails",
      title: "Step 1: Your details",
    },
    {
      name: "Criteria1",
      title: "Step 2: Eligibility (1/2)",
    },
    {
      name: "Criteria2",
      title: "Step 3: Eligibility (2/2)",
    },
    {
      name: "EligibilityProof",
      title: "Step 4: Proof of Eligibility",
    },
    {
      name: "Summary",
      title: "Step 5: Summary",
    },
  ],
}

export const mutations = {
  ...untestedMutations,
  // Tested mutations below
}

export const actions = {
  ...untestedActions,
  // Tested actions below
}

export const getters = {
  ...untestedGetters,
  // Tested getters below
  criteriaLoaded: (state) =>
    Boolean(state.primaryCriteriaArray && state.secondaryCriteriaMap),
}

export default {
  state,
  mutations,
}
