<template>
  <CriteriaSelector
    v-if="pageContent"
    :criteria="secondaryCriteriaArray"
    :initial-value="form.criteria2"
    v-bind="$attrs"
    @go-forward="updateCriterion"
    @go-back="$emit('go-back')"
  >
    <Article :text-content="criteriaDescription" />
  </CriteriaSelector>
</template>

<script>
import CriteriaSelector from "@/components/CriteriaSelector"
import Article from "@soenergy/frontend-library/src/components/Article"
import { mapMutations, mapState, mapGetters } from "vuex"

import cmsPreviewMixin from "@soenergy/frontend-library/src/mixins/cmsPreviewMixin"
import criteria2Content from "soenergy-cms-loader!?path=whd/criteria2"

export default {
  components: {
    CriteriaSelector,
    Article,
  },
  emits: ["go-back", "go-forward"],
  mixins: [cmsPreviewMixin({ story: criteria2Content })],
  computed: {
    ...mapState("whdApplication", ["form"]),
    ...mapGetters("whdApplication", ["secondaryCriteriaArray"]),
    criteriaDescription() {
      return this.pageContent.description[0] &&
        this.pageContent.description[0].textContent
        ? this.pageContent.description[0].textContent
        : {}
    },
  },
  methods: {
    ...mapMutations("whdApplication", {
      setSecondaryCriterion: "SET_SECONDARY_CRITERION",
    }),
    updateCriterion(selected) {
      this.setSecondaryCriterion(selected)
      this.$emit("go-forward", selected)
    },
  },
}
</script>
