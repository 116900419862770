<template>
  <section class="step-wrapper">
    <div class="columns">
      <ProgressIndicator
        class="column is-12 progress-indicator"
        :steps="steps"
        :current-step-number="currentStepNumber"
        :furthest-step-reached="furthestStepReached"
        :clickable="true"
        @click-stage="goToSpecificStep"
      />
    </div>
    <router-view
      @go-back="goToPreviousStep"
      @go-forward="goToNextStep"
      @go-specific="goToSpecificStep"
    />
  </section>
</template>

<script>
import { mapState } from "vuex"
import ProgressIndicator from "@soenergy/frontend-library/src/components/ProgressIndicator"

export default {
  components: {
    ProgressIndicator,
  },
  data() {
    return {
      furthestStepReached: 0,
    }
  },
  computed: {
    ...mapState({
      steps: (state) => state.whdApplication.steps,
    }),
    currentStep() {
      return this.$route.name
    },
    currentStepNumber() {
      return this.steps.findIndex((step) => step.name === this.currentStep)
    },
  },
  methods: {
    async goToStep(stepNumber) {
      const stepRouteName = this.steps[stepNumber].name
      await this.$router.push({
        name: stepRouteName,
      })
    },
    async goToNextStep() {
      this.$store.commit("whdApplication/SET_STEP_COMPLETED", {
        page: this.currentStepNumber,
        value: true,
      })
      await this.goToStep(this.currentStepNumber + 1)
      this.furthestStepReached = this.currentStepNumber
    },
    async goToPreviousStep() {
      if (this.currentStepNumber === 0) {
        this.$router.push("eligibility")
        return
      }
      this.$store.commit("whdApplication/SET_STEP_COMPLETED", {
        page: this.currentStepNumber - 1,
        value: false,
      })
      await this.goToStep(this.currentStepNumber - 1)
      this.furthestStepReached = this.currentStepNumber
    },
    goToSpecificStep(requestedIndex) {
      const stepReachable = requestedIndex <= this.furthestStepReached
      const isCurrentStep = requestedIndex === this.currentStepNumber
      if (stepReachable && !isCurrentStep) {
        this.goToStep(requestedIndex)

        // invalid pages further ahead
        this.steps.map((step, index) => {
          if (index >= requestedIndex) {
            this.$store.commit("whdApplication/SET_STEP_COMPLETED", {
              page: index,
              value: false,
            })
          }
        })
        this.furthestStepReached = requestedIndex
      }
    },
  },
}
</script>

<style scoped lang="scss">
.progress-indicator {
  margin-bottom: $spacing-4;
}
</style>
