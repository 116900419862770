<template>
  <section v-if="pageContent" class="completed step-wrapper">
    <div class="step-content">
      <div class="completed__heading-content">
        <i class="icon icon-check"></i>
        <h1>{{ pageContent.title }}</h1>
      </div>
      <div class="completed__content">
        <Article :text-content="completedDescription" />
      </div>
    </div>

    <div class="step-nav-buttons">
      <AppButton class="step-nav-buttons__next" :href="websiteAccountUrl">
        <span>{{ pageContent.backBtnText }}</span>
      </AppButton>
    </div>
  </section>
</template>

<script>
import AppButton from "@soenergy/frontend-library/src/components/AppButton"
import Article from "@soenergy/frontend-library/src/components/Article"

import cmsPreviewMixin from "@soenergy/frontend-library/src/mixins/cmsPreviewMixin"
import completedContent from "soenergy-cms-loader!?path=whd/completed"

export default {
  components: {
    AppButton,
    Article,
  },
  mixins: [cmsPreviewMixin({ story: completedContent })],
  data() {
    return {
      websiteAccountUrl: `${process.env.VUE_APP_ACCOUNT_APP_URL}`,
    }
  },
  computed: {
    completedDescription() {
      return this.pageContent.description[0] &&
        this.pageContent.description[0].textContent
        ? this.pageContent.description[0].textContent
        : {}
    },
  },
}
</script>

<style scoped lang="scss">
.completed {
  &__heading-content {
    background: $earth;
    text-align: center;
    position: relative;
    min-height: 150px;
    padding-top: $spacing-5;

    margin: (-$spacing-4) (-$spacing-4) 0 (-$spacing-4);

    @include sm {
      margin: (-$spacing-4) (-$spacing-7) 0 (-$spacing-7);
    }

    @include md {
      text-align: left;
      padding: $spacing-8 0 0 $spacing-7;
      min-height: 200px;
    }

    h1,
    .icon-check {
      position: relative;
      z-index: 1;

      @include md {
        font-size: $size-1;
      }
    }

    h1 {
      @include md {
        display: inline-block;
      }
    }

    .icon-check {
      border: 2px solid $night;
      border-radius: 50%;
      padding: 4px;
      font-size: 1.25rem;
      margin-bottom: $spacing-4;
      font-weight: $weight-bold;

      @include md {
        font-size: $size-5;
        vertical-align: text-bottom;
        margin: 0 $spacing-7 0 0;
        padding: 12px;
      }
    }
  }

  &__content {
    padding: $spacing-7 0;

    @include md {
      padding: $spacing-7 0;
    }
  }
}
</style>
