import { createStore } from "vuex"

import * as whdApplication from "./modules/whdApplication"
import {
  untestedActions,
  untestedGetters,
  untestedMutations,
} from "@/store/untested"

export default createStore({
  modules: {
    whdApplication,
  },
  state: {
    accountId: null,
    showAuthError: false,
    loading: false,
    customerDetails: null,
  },
  mutations: {
    ...untestedMutations,
    // Tested mutations below
  },
  actions: {
    ...untestedActions,
    // Tested actions below
  },
  getters: {
    ...untestedGetters,
    // Tested getters below
  },
})
